<template>
  <TopBar />

  <main class="d-flex flex-column p-2 p-md-4">
    <div class="container my-4 flex-grow-1">
      <h1 class="fw-bold fs-2">{{ $t('feedback.title') }}</h1>

      <div v-if="!isSubmitSuccessful && !isSubmitFailed">
        <div v-if="feedbackType === 'not-no-show'">
          <p>{{ $t('feedback.feedback_request_1') }}</p>
          <p>{{ $t('feedback.feedback_request_2') }}</p>
        </div>
        <p v-else>{{ $t('feedback.noshow_feedback_request') }}</p>
        <p>{{ $t('feedback.your_comment') }}</p>

        <div class="col-12 col-md-8 col-lg-6">
          <textarea 
            id="feedback" 
            name="feedback" 
            rows="5"
            v-model.trim="customerFeedback"
            class="form-control"
            @keydown.enter.prevent="handleFeedbackSubmit"
            :class="{
              'is-valid': customerFeedback
            }"
          >
          </textarea>

          <div class="text-center my-4">
            <button
              class="btn btn-success px-4 py-2 rounded-3"
              :disabled="!customerFeedback"
              type="submit"
              @click="handleFeedbackSubmit"
              >
               {{ $t('send') }}
            </button>
          </div>
        </div>
      </div>

      <div v-else>
        <p class="fs-5 fw-bold" :class="isSubmitSuccessful ? 'text-success' : 'text-danger'">
          {{ isSubmitSuccessful ? $t('success_heading') : $t('error_heading') }}
        </p>
        <p v-if="isSubmitFailed">{{ $t('error_message') }}</p>
      </div>
    </div>

    <MediaPartners :mediaPartners="mediaPartners" />
  </main>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useLocaleStore } from "@/stores/localeStore";

import MediaPartners from "@/components/MediaPartners.vue";
import TopBar from '@/components/TopBar.vue';

import { useFeedback } from "@/composables/useFeedback";

const props = defineProps<{
  locale: string,
  mediaPartners: {
    postTitle: string,
    postImage: string,
  }[],
  feedbackType: string,
  nonceToken: string
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

const token = new URLSearchParams(window.location.search).get('t');
const customerFeedback = ref<string>('');
const { isSubmitSuccessful, isSubmitFailed, submitFeedback } = useFeedback();

const handleFeedbackSubmit = () => {
  submitFeedback(token, customerFeedback.value, props.nonceToken, props.feedbackType);
};
</script>

<style scoped>
main {
  min-height: 75vh;
}
</style>
